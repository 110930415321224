import { createContext, ReactElement, useMemo, useContext, useState, useCallback, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { RestaurantDetailsInterface, RestaurantInterface, RestaurantsInterface } from '../../types/RestaurantInterface';
import { COOKIE_RESTAURANT_ID } from '../../constants/AppConstants';
import { Menu } from '../../types/MenuInterface';
import { getRestaurantDetails, getRestaurants } from '../../api/restaurant';
import { useModalContext, openModal } from '../ModalContext';
import { useAuthContext } from '../AuthContext';
import { MenuSection } from '../../types/MenuSectionInterface';

interface RestaurantContextInterface {
  setRestaurant: Function;
  addRestaurant: Function;
  updateRestaurant: Function;
  addMenu: Function;
  removeMenu: Function;
  reorderMenus: Function;
  updateMenu: Function;
  addMenuSection: Function;
  updateMenuSection: Function;
  removeMenuSection: Function;
  currentRestaurant: RestaurantDetailsInterface;
  restaurants: RestaurantInterface[];
  menus: Menu[];
  setMenus: Function;
  isLoading: boolean;
  hasError: boolean;
}

interface RestaurantProviderInterface {
  children: ReactElement;
}

const RestaurantContext = createContext<RestaurantContextInterface>({
  currentRestaurant: null,
  restaurants: null,
  menus: [],
  addMenu: () => {},
  removeMenu: () => {},
  reorderMenus: () => {},
  updateMenu: () => {},
  addMenuSection: () => {},
  updateMenuSection: () => {},
  removeMenuSection: () => {},
  setRestaurant: () => {},
  addRestaurant: () => {},
  updateRestaurant: () => {},
  setMenus: () => {},
  isLoading: false,
  hasError: false
});

const RestaurantProvider = ({ children }: RestaurantProviderInterface) => {
  const { dispatch } = useModalContext();
  const { removeToken } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [restaurants, setRestaurants] = useState<RestaurantInterface[]>(null);
  const [currentRestaurant, setCurrentRestaurant] = useState<RestaurantDetailsInterface>(null);
  const [menus, setMenus] = useState<Menu[]>([]);

  const [cookies, setCookies] = useState(new Cookies());

  const setRestaurant = useCallback(
    async (restaurantID: number) => {
      setIsLoading(true);

      let currentRestaurantClone: RestaurantDetailsInterface;
      let menusClone: Menu[];
      // clear current restaurant and menus if existing
      if (currentRestaurant != null) {
        currentRestaurantClone = { ...currentRestaurant };
        menusClone = menus.slice();
        setCurrentRestaurant(null);
        setMenus([]);
      }

      cookies.set(COOKIE_RESTAURANT_ID, restaurantID, { path: '/' });
      setCookies(cookies);

      try {
        const response = await getRestaurantDetails();
        setCurrentRestaurant(response);
        setMenus(response.menus);
        setIsLoading(false);
      } catch (error) {
        if (error.response?.status !== 401) {
          // on non auth related failure indicate failure has occurred
          setHasError(true);

          if (Object.keys(currentRestaurantClone).length) {
            setCurrentRestaurant(currentRestaurantClone);
            setMenus(menusClone);
          }

          openModal({ dispatch, onDismiss: () => removeToken() });
        }
        setIsLoading(false);
      }
    },
    [cookies, currentRestaurant, dispatch, menus, removeToken]
  );

  const addRestaurant = useCallback(
    (restaurant: RestaurantDetailsInterface) => {
      const _restaurants: RestaurantInterface[] = restaurants?.slice();

      const insertedRestaurant: RestaurantInterface = { menus: [], ...restaurant };
      _restaurants.push(insertedRestaurant);

      setRestaurants(_restaurants);
      setCurrentRestaurant({ ...restaurant });
      setMenus([]);
      cookies.set(COOKIE_RESTAURANT_ID, restaurant.restaurantID, { path: '/' });
      setCookies(cookies);
    },
    [restaurants, cookies]
  );

  const updateRestaurant = useCallback(
    (restaurant: RestaurantDetailsInterface) => {
      const _restaurants: RestaurantInterface[] = restaurants?.slice();
      const restaurantIndex = _restaurants.findIndex(
        (_restaurant) => _restaurant.restaurantID === currentRestaurant.restaurantID
      );

      _restaurants[restaurantIndex] = { ...restaurant };

      setRestaurants(_restaurants);
      setCurrentRestaurant(restaurant);
      setMenus([...(restaurant?.menus ?? [])]);
    },
    [currentRestaurant?.restaurantID, restaurants]
  );

  const addMenu = useCallback(
    (menu: Menu) => {
      const existingMenus = menus.slice();
      existingMenus.push(menu);
      updateRestaurant({ ...currentRestaurant, menus: existingMenus });
    },
    [currentRestaurant, menus, updateRestaurant]
  );

  const removeMenu = useCallback(
    (menuID: number) => {
      const _menus: Menu[] = menus.slice();
      const menuIndex: number = _menus.findIndex((menu) => menu.menuID === menuID);
      _menus.splice(menuIndex, 1);

      updateRestaurant({ ...currentRestaurant, menus: _menus });
    },
    [currentRestaurant, menus, updateRestaurant]
  );

  const reorderMenus = useCallback(
    (menuIDs: number[]) => {
      const _menus: Menu[] = [];

      menuIDs.forEach((id) => {
        const _menu: Menu[] = menus.filter((menu) => menu.menuID === id);
        if (_menu.length === 1) {
          _menus.push(_menu[0]);
        }
      });

      updateRestaurant({ ...currentRestaurant, menus: _menus });
    },
    [currentRestaurant, menus, updateRestaurant]
  );

  const updateMenu = useCallback(
    (updatedMenu: Menu) => {
      const _menus: Menu[] = menus.slice();
      const menuIndex = _menus.findIndex((menu) => menu.menuID === updatedMenu.menuID);
      _menus.splice(menuIndex, 1, { ..._menus[menuIndex], ...updatedMenu });

      updateRestaurant({ ...currentRestaurant, menus: _menus });
    },
    [currentRestaurant, menus, updateRestaurant]
  );

  const addMenuSection = useCallback(
    (menuSection: MenuSection, menuID: number) => {
      const _menus = menus.slice();
      const menuIndex = _menus.findIndex((menu) => menu.menuID === menuID);
      _menus[menuIndex].menuSections.push(menuSection);
      updateMenu(_menus[menuIndex]);
    },
    [menus, updateMenu]
  );

  const updateMenuSection = useCallback(
    (menuSectionID: number, menuSectionName: string, menuID: number, message: string) => {
      const _menus = menus.slice();
      const menuIndex = _menus.findIndex((menu) => menu.menuID === menuID);
      const menuSectionIndex: number = _menus[menuIndex].menuSections.findIndex(
        (section) => section.menuSectionID === menuSectionID
      );
      _menus[menuIndex].menuSections[menuSectionIndex].name = menuSectionName;
      _menus[menuIndex].menuSections[menuSectionIndex].message = message;
      updateMenu(_menus[menuIndex]);
    },
    [menus, updateMenu]
  );

  const removeMenuSection = useCallback(
    (menuSectionID: number, menuID: number) => {
      const _menus = menus.slice();
      const menuIndex = _menus.findIndex((menu) => menu.menuID === menuID);
      const menuSectionIndex: number = _menus[menuIndex].menuSections.findIndex(
        (section) => section.menuSectionID === menuSectionID
      );
      _menus[menuIndex].menuSections.splice(menuSectionIndex, 1);
      updateMenu(_menus[menuIndex]);
    },
    [menus, updateMenu]
  );

  useEffect(() => {
    let canceled = false;
    if (restaurants == null) {
      setIsLoading(true);
      getRestaurants()
        .then(async (data: RestaurantsInterface) => {
          if (canceled) return;

          setRestaurants(data.restaurants);
          if (data.restaurants.length > 0) {
            const currentID: string = cookies.get(COOKIE_RESTAURANT_ID);
            if (currentID) {
              const restaurantIdx = data.restaurants.findIndex(
                (restaurant) => restaurant?.restaurantID === parseInt(currentID, 10)
              );
              await setRestaurant(
                restaurantIdx !== -1
                  ? data.restaurants?.[restaurantIdx]?.restaurantID
                  : data.restaurants?.[0]?.restaurantID
              );
            } else {
              await setRestaurant(data.restaurants[0]?.restaurantID);
            }
          }

          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;

          if (error.response?.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
            openModal({ dispatch, onDismiss: () => removeToken() });
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [restaurants, setRestaurant, dispatch, removeToken, cookies]);

  const providerValue = useMemo(
    () => ({
      currentRestaurant,
      restaurants,
      hasError,
      isLoading,
      menus,
      setRestaurant,
      addRestaurant,
      updateRestaurant,
      setMenus,
      addMenu,
      removeMenu,
      reorderMenus,
      updateMenu,
      addMenuSection,
      updateMenuSection,
      removeMenuSection
    }),
    [
      currentRestaurant,
      restaurants,
      hasError,
      isLoading,
      menus,
      setRestaurant,
      addRestaurant,
      updateRestaurant,
      setMenus,
      addMenu,
      addMenuSection,
      removeMenu,
      reorderMenus,
      updateMenu,
      updateMenuSection,
      removeMenuSection
    ]
  );

  return <RestaurantContext.Provider value={providerValue}>{children}</RestaurantContext.Provider>;
};

const useRestaurantContext = () => {
  const context = useContext(RestaurantContext);
  if (!context) {
    throw new Error(`RestaurantContext must be used within the RestaurantProvider component`);
  }
  return context;
};

export { RestaurantProvider as default, useRestaurantContext };
