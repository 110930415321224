import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import logoWithName from '../../assets/images/TapTabLogoBlackSmallWithName.png';
import restaurantImage from '../../assets/images/BalthazarChampagneBar.png';
import TapTabLogoPlaceholder from '../../assets/images/TapTabLogoPlaceholder.png';
import { useRestaurantContext } from '../../contexts/RestaurantContext';
import 'react-loading-skeleton/dist/skeleton.css';
import { RestaurantDetailsInterface } from '../../types/RestaurantInterface';
import { PowerIcon, ForkIcon, GearIcon, MegaphoneIcon } from '../../assets/svgs/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  PAGE_CREATE_DRINK_ITEM,
  PAGE_CREATE_MENU,
  PAGE_CREATE_MENU_ITEM,
  PAGE_CREATE_MODIFIER,
  PAGE_CREATE_RESTAURANT,
  PAGE_DISCOVERY_CONTENT,
  PAGE_EDIT_DRINK_ITEM,
  PAGE_EDIT_MENU,
  PAGE_EDIT_MENU_ITEM,
  PAGE_EDIT_MODIFIER,
  PAGE_EDIT_RESTAURANT,
  PAGE_EMAIL_MANAGER,
  PAGE_MEDIA_LIBRARY,
  PAGE_MENUS,
  PAGE_PROMOTIONS,
  PAGE_PRESS,
  PAGE_ABOUT,
  PAGE_RESERVATION_ORDERING_LINKS,
  PAGE_SETTINGS
} from '../../constants/UriConstants';
import { Dropdown } from '../common/Form';
import SidebarLink from './SidebarLink';
import EmailIcon from '../../assets/svgs/icons/EmailIcon/EmailIcon';
import { useMenuContext } from '../../contexts/MenuContext';
import MediaIcon from '../../assets/svgs/icons/MediaIcon';
import AboutPageIcon from '../../assets/svgs/icons/AboutPageIcon/AboutPageIcon';
import PressPageIcon from '../../assets/svgs/icons/PressPageIcon/PressPageIcon';
import OrderReservationIcon from '../../assets/svgs/icons/OrderReservationIcon';

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentRestaurant, setRestaurant, restaurants } = useRestaurantContext();
  const { resetMenu } = useMenuContext();
  const { removeToken } = useAuthContext();

  const handleRestaurantSelected = (restaurantID: string) => {
    const selected = restaurants?.find((restaurant) => restaurant.restaurantID === parseInt(restaurantID, 10));
    resetMenu();
    setRestaurant(selected?.restaurantID);
  };

  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = restaurantImage;
  };
  const getRestaurantImage = (selectedRestaurant: RestaurantDetailsInterface) => {
    // if no restaurants exist for user
    // display default profile image
    if (restaurants?.length === 0) {
      return <img src={TapTabLogoPlaceholder} alt="Tap Tap Restaurant placeholders" />;
    }

    // if restaurants exist but selected restaurant is null
    // then display loading skeleton
    if (!selectedRestaurant) {
      return <Skeleton circle width="90px" height="90px" />;
    }

    return (
      <img
        onError={imageOnErrorHandler}
        src={
          selectedRestaurant?.images?.profile?.[0]?.imageURL
            ? selectedRestaurant?.images?.profile?.[0]?.imageURL
            : restaurantImage
        }
        className="sidebar-restaurant-icon"
        alt="Restaurant"
      />
    );
  };

  const getRestaurantName = (selectedRestaurant: RestaurantDetailsInterface) => {
    // if no restaurants exist for user
    // display nothing
    if (restaurants?.length === 0) {
      return null;
    }

    // if restaurants exist but selected restaurant is null
    // then display loading skeleton
    if (!selectedRestaurant) {
      return <Skeleton width="66%" />;
    }
    if (
      restaurants?.length > 1 &&
      !(
        pathname.includes(PAGE_CREATE_MODIFIER) ||
        pathname.includes(PAGE_EDIT_MODIFIER) ||
        pathname.includes(PAGE_CREATE_DRINK_ITEM) ||
        pathname.includes(PAGE_EDIT_DRINK_ITEM) ||
        pathname.includes(PAGE_CREATE_MENU) ||
        pathname.includes(PAGE_EDIT_MENU) ||
        pathname.includes(PAGE_CREATE_MENU_ITEM) ||
        pathname.includes(PAGE_EDIT_MENU_ITEM) ||
        pathname.includes(PAGE_CREATE_RESTAURANT) ||
        pathname.includes(PAGE_EDIT_RESTAURANT)
      )
    ) {
      return (
        <Dropdown
          className="sidebar-restaurant-dropdown"
          onChange={handleRestaurantSelected}
          value={currentRestaurant.restaurantID}
          values={restaurants?.map((restaurant) => ({ label: restaurant.name, value: restaurant.restaurantID }))}
        />
      );
    }
    return <div className="sidebar-restaurant-name">{selectedRestaurant.name}</div>;
  };

  const getRestaurantID = (selectedRestaurant: RestaurantDetailsInterface) => {
    // if no restaurants exist for user
    // display default profile copy
    if (restaurants?.length === 0) {
      return <div className="sidebar-restaurant-id">Profile</div>;
    }
    // if restaurants exist but selected restaurant is null
    // then display loading skeleton
    if (!selectedRestaurant) {
      return <Skeleton width="33%" />;
    }
    return <div className="sidebar-restaurant-id">{selectedRestaurant.restaurantUrlID}</div>;
  };

  const handleLogoClicked = () => {
    navigate('/');
  };

  const handleLogoKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      navigate('/');
    }
  };

  const handleLogoutKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      removeToken?.();
    }
  };

  const handleActive = (path: string) =>
    pathname === path ||
    pathname.startsWith(`${path}/`) ||
    (pathname === '/' && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-menu') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-menu-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-drink-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-menu') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-menu-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-drink-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-restaurant') && path === `/${PAGE_SETTINGS}`);

  const isMenuActive = handleActive(`/${PAGE_MENUS}`);
  const isPromotionsActive = handleActive(`/${PAGE_PROMOTIONS}`);
  const isEmailActive = handleActive(`/${PAGE_EMAIL_MANAGER}`);
  const isReserveAndOrderActive = handleActive(`/${PAGE_RESERVATION_ORDERING_LINKS}`);
  const isSettingsActive = handleActive(`/${PAGE_SETTINGS}`);
  const isPressActive = handleActive(`/${PAGE_PRESS}`);
  const isAboutActive = handleActive(`/${PAGE_ABOUT}`);
  const isMediaActive = handleActive(`/${PAGE_MEDIA_LIBRARY}`);
  const isDiscoveryContentActive = handleActive(`/${PAGE_DISCOVERY_CONTENT}`);

  return (
    <div className="sidebar-container">
      <div className="sidebar-header-container">
        <div
          role="button"
          onClick={handleLogoClicked}
          onKeyPress={handleLogoKeyPress}
          tabIndex={0}
          className="sidebar-logo-container"
        >
          <img src={logoWithName} className="sidebar-logo" alt="TapTab logo" />
        </div>
        <div className="sidebar-profile-container">
          <div className="sidebar-image-container">{getRestaurantImage(currentRestaurant)}</div>
          <div className="sidebar-info-container">
            {getRestaurantName(currentRestaurant)}
            {getRestaurantID(currentRestaurant)}
          </div>
        </div>
      </div>
      <ul className="sidebar-link-container">
        <SidebarLink
          name="PROMOTIONS"
          path={`/${PAGE_PROMOTIONS}`}
          icon={<MegaphoneIcon />}
          isActive={isPromotionsActive}
        />
        <SidebarLink name="ABOUT" path={`/${PAGE_ABOUT}`} icon={<AboutPageIcon />} isActive={isAboutActive} />
        <SidebarLink name="PRESS" path={`/${PAGE_PRESS}`} icon={<PressPageIcon />} isActive={isPressActive} />
        <SidebarLink name="MENUS" path={`/${PAGE_MENUS}`} icon={<ForkIcon />} isActive={isMenuActive} />
        <SidebarLink
          name="ONLINE ORDERING & RESERVATION LINKS"
          path={`/${PAGE_RESERVATION_ORDERING_LINKS}`}
          icon={<OrderReservationIcon />}
          isActive={isReserveAndOrderActive}
        />
        <SidebarLink name="EMAILS" path={`/${PAGE_EMAIL_MANAGER}`} icon={<EmailIcon />} isActive={isEmailActive} />
        <SidebarLink name="MEDIA" path={`/${PAGE_MEDIA_LIBRARY}`} icon={<MediaIcon />} isActive={isMediaActive} />
        <SidebarLink
          name="DISCOVERY CONTENT"
          path={`/${PAGE_DISCOVERY_CONTENT}`}
          icon={<MegaphoneIcon />}
          isActive={isDiscoveryContentActive}
        />
        <SidebarLink
          name="PROFILE & SETTINGS"
          path={`/${PAGE_SETTINGS}`}
          icon={<GearIcon />}
          isActive={isSettingsActive}
        />
      </ul>
      <div className="logout-container">
        <div
          role="button"
          className="logout-button"
          onClick={() => removeToken()}
          onKeyPress={handleLogoutKeyPress}
          tabIndex={0}
        >
          <PowerIcon />
          <span>LOGOUT</span>
        </div>
      </div>
      <div hidden>Version {process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default Sidebar;
